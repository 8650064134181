import Storage, { KEY_STEREO_MODE } from '@/common/services/Storage';
import { AUTOSTEREO_MODE, GLASSES_MODE } from '@/stereo/constants/mode';
export function getCurrentStereoMode() {
    return Storage.getObject(KEY_STEREO_MODE, Storage.STORAGE_LOCAL);
}
export function isGlassesMode() {
    return getCurrentStereoMode() === GLASSES_MODE;
}
export function isAutoStereoMode() {
    return getCurrentStereoMode() === AUTOSTEREO_MODE;
}
