import {
  MEDIA_SIZE_160, MEDIA_SIZE_320, MEDIA_SIZE_480, MEDIA_SIZE_640, MEDIA_SIZE_960,
} from '../../constants/sizes';

export const MEDIA_SIZES = [MEDIA_SIZE_160, MEDIA_SIZE_320, MEDIA_SIZE_480, MEDIA_SIZE_640, MEDIA_SIZE_960];

export const DEVIATION_0 = 0;
export const DEVIATION_90 = 90;
export const DEVIATION_180 = 180;
export const DEVIATION_270 = 270;

export const AMPLITUDE_SECTOR = 18;
export const AMPLITUDE_360 = 0;

export default class PlayerAPI {
  /**
   * @returns {string}
   */
  static get PLAY() {
    return CutwisePlayer.EVENT_AFTER_PLAY;
  }

  /**
   * @returns {string}
   */
  static get PAUSE() {
    return CutwisePlayer.EVENT_AFTER_PAUSE;
  }

  /**
   * @returns {string}
   */
  static get UPDATE() {
    return CutwisePlayer.EVENT_AFTER_CHANGE_SETTINGS;
  }

  /**
   * @returns {string}
   */
  static get EVENT_SOURCE_INIT() {
    return CutwisePlayer.EVENT_SOURCE_INIT;
  }

  /**
   * @returns {string}
   */
  static get ENABLE_FULLSCREEN() {
    return CutwisePlayer.EVENT_ENABLE_FULLSCREEN;
  }

  /**
   * @returns {string}
   */
  static get DISABLE_FULLSCREEN() {
    return CutwisePlayer.EVENT_DISABLE_FULLSCREEN;
  }

  /**
   * @type {Array}
   */
  static onLoadCallbacks = [];

  /**
   * @param {PlayerSelector} selector
   * @param {PlayerSettings} settings
   */
  static play(selector, settings) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerPlay(group, settings);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   * @param {number} shift
   */
  static shift(selector, shift) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerShiftFrame(group, shift);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   * @param {Object} viewSettings
   * @param {boolean} viewSettings.showInclusions
   * @param {boolean} viewSettings.showMedia
   * @param {string} viewSettings.inclusionTypes
   */
  static changePlottingViewMode(selector, viewSettings) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.changePlottingViewMode(group, viewSettings);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   * @param {PlayerSettings} settings
   */
  static pause(selector, settings) {
    if (!window.CutwisePlayer) {
      return;
    }
    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerPause(group, settings);
      });
    }
  }

  static stop() {
    // todo
  }

  /**
   * @param {PlayerSelector} selector
   * @param {PlayerSettings} settings
   */
  static update(selector, settings) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerSetSettings(group, settings);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   * @param {PlayerFullscreenSettings} settings
   */
  static triggerFullscreen(selector, settings) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerFullscreen(group, settings);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   * @param {PlayerFullscreenSettings} settings
   */
  static triggerStereo(selector, settings) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerStereo(group, settings);
      });
    }
  }

  /**
   * @param {PlayerSelector} selector
   */
  static resetPosition(selector) {
    if (!window.CutwisePlayer) {
      return;
    }

    if (selector && selector.trackedGroups.length > 0) {
      selector.trackedGroups.forEach((group) => {
        window.CutwisePlayer.triggerResetPosition(group);
      });
    }
  }

  /**
   * @param {string} event
   * @param {callback} callback
   */
  static on(event, callback) {
    if (!window.CutwisePlayer) {
      return;
    }
    window.CutwisePlayer.on(event, callback);
  }

  /**
   * @param {string} event
   * @param {callback} callback
   */
  static off(event, callback) {
    if (!window.CutwisePlayer) {
      return;
    }
    window.CutwisePlayer.off(event, callback);
  }

  /**
   * @return {boolean}
   */
  static isFetched() {
    return Boolean(window.CutwisePlayer);
  }

  static triggerLoad() {
    PlayerAPI.onLoadCallbacks.forEach(callback => callback());
  }

  static onLoad(callback) {
    if (window.CutwisePlayer) {
      callback();
    } else {
      PlayerAPI.onLoadCallbacks.push(callback);
    }
  }
}
