import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Button.less';
import Link, {NONE} from '../Link/Link';
import Loader, {SMALL, WHITE, X_SMALL} from '../Loader/Loader';

export const SIZE_XSMALL = 'xs';
export const SIZE_SMALL = 's';
export const SIZE_MEDIUM = 'm';
export const SIZE_XLARGE = 'xl';

export const DEFAULT = 'default';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const QUATERNARY = 'quaternary';
export const ACCENT = 'accent';
export const GHOST = 'ghost';
export const GHOST_WARNING = 'ghost-warning';
export const TRANSPARENT = 'transparent';
export const BORDERED = 'bordered';
export const NEUTRAL_NOT_SO_LIGHT = 'neutral-not-so-light';

export const TEXT_COLOR_DARK = 'text-color-dark';
export const TEXT_COLOR_LIGHT = 'text-color-light';

export const ACTIVE_COLOR_DEFAULT = 'active-default';
export const ACTIVE_COLOR_AMBIENT = 'active-ambient';

export const LEFT_ICON_POSITION = 'left';
export const RIGHT_ICON_POSITION = 'right';

const CLASS_COLOR_MAP = {
  [DEFAULT]: styles.buttonDefault,
  [PRIMARY]: styles.buttonPrimary,
  [SECONDARY]: styles.buttonSecondary,
  [TERTIARY]: styles.buttonTertiary,
  [QUATERNARY]: styles.buttonQuaternary,
  [ACCENT]: styles.buttonAccent,
  [GHOST]: styles.buttonGhost,
  [GHOST_WARNING]: styles.buttonGhostWarning,
  [TRANSPARENT]: styles.buttonTransparent,
  [BORDERED]: styles.buttonBordered,
  [NEUTRAL_NOT_SO_LIGHT]: styles.buttonNeutralNotSoLight,
};

const CLASS_TEXT_COLOR_MAP = {
  [TEXT_COLOR_DARK]: styles.buttonTextColorDark,
  [TEXT_COLOR_LIGHT]: styles.buttonTextColorLight,
};

const CLASS_ICON_POSITION_MAP = {
  [LEFT_ICON_POSITION]: styles.buttonIconLeft,
  [RIGHT_ICON_POSITION]: styles.buttonIconRight,
};

export default class Button extends Component {
  static propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.oneOf(Object.keys(CLASS_COLOR_MAP)),
    textColor: PropTypes.oneOf(Object.keys(CLASS_TEXT_COLOR_MAP)),
    size: PropTypes.oneOf([SIZE_XSMALL, SIZE_SMALL, SIZE_MEDIUM, SIZE_XLARGE]),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    href: PropTypes.string,
    /*
    * `block` will make button full width of parent
    * */
    block: PropTypes.bool,
    active: PropTypes.bool,
    activeColor: PropTypes.oneOf([ACTIVE_COLOR_DEFAULT, ACTIVE_COLOR_AMBIENT]),
    /*
    * `silent` will prevent any interaction effect on button, like: hover, focus, etc
    * */
    silent: PropTypes.bool,
    disabled: PropTypes.bool,
    // `withIcon` will make button to Icon Button
    withIcon: PropTypes.bool,
    // `withImage` will make button to Button with image inside
    withImage: PropTypes.bool,
    /*
    * `textOverflow` will render '...' in the end of text, if text larger than button width
    * */
    textOverflow: PropTypes.string,
    target: PropTypes.string,
    form: PropTypes.string,
    iconPosition: PropTypes.oneOf([LEFT_ICON_POSITION, RIGHT_ICON_POSITION]),
    externalURL: PropTypes.bool,
    withoutPaddings: PropTypes.bool,
    loading: PropTypes.bool,
    wide: PropTypes.bool,
  };

  static defaultProps = {
    type: 'button',
    children: null,
    color: DEFAULT,
    activeColor: ACTIVE_COLOR_DEFAULT,
    textColor: null,
    size: SIZE_MEDIUM,
    className: '',
    contentClassName: '',
    block: false,
    silent: false,
    active: null,
    withIcon: false,
    withImage: false,
    iconPosition: null,
    href: null,
    target: null,
    externalURL: false,
    disabled: false,
    withoutPaddings: false,
    loading: false,
    wide: false,
    form: undefined,
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const {
      type, color, block, silent, size, active, activeColor, className, contentClassName, disabled, withIcon, withImage, iconPosition, href, externalURL, target, withoutPaddings, textColor, loading, textOverflow, wide, form, ...rest
    } = this.props;

    const btnClassName = cls(
      styles.btn,
      CLASS_COLOR_MAP[color],
      {
        [CLASS_TEXT_COLOR_MAP[textColor]]: textColor,
        'btn--icon': withIcon,
        'btn--image': withImage,
      },
      `btn--${size}`,
      {
        'btn--block': block,
        'btn--active': active,
        'btn--active-default': active && activeColor === ACTIVE_COLOR_DEFAULT,
        'btn--active-ambient': active && activeColor === ACTIVE_COLOR_AMBIENT,
        'btn--disabled': disabled,
        'btn--without-paddings': withoutPaddings,
        'btn--silent': silent,
        'btn--text-eillipsis': textOverflow === 'ellipsis',
        [styles.btnWide]: wide,
        [styles.btnLoading]: loading,
      },
      className,
    );

    if (active !== null) {
      rest['aria-pressed'] = active;
    }

    const content = (
      <>
        <span className={cls(
          styles.btnContentWrapper,
          {
            [CLASS_ICON_POSITION_MAP[iconPosition]]: !!iconPosition,
            [styles.btnHideChildren]: loading,
          },
          contentClassName,
        )}
        >
          {this.props.children}
        </span>

        {loading && (
          <Loader className={styles.btnLoader} appearance={WHITE} size={size === SIZE_XSMALL ? X_SMALL : SMALL} />
        )}
      </>
    );

    if (href) {
      if (externalURL || href.startsWith('http') || href.startsWith('mailto')) {
        return <Link className={btnClassName} href={href} target={target} {...rest} appearance={NONE}>{content}</Link>;
      }

      return <Link className={btnClassName} to={href} {...rest} appearance={NONE}>{content}</Link>;
    }

    return <button form={form} className={btnClassName} type={type} {...rest}>{content}</button>;
  }
}
