export const CODE_BRIGHTNESS = 'brightness';
export const CODE_BRIGHTNESS2 = 'brilliance2';
export const CODE_SYMMETRY = 'symmetry';
export const CODE_FIRE = 'fire';
export const CODE_SCINTILLATION = 'scintillation';
export const CODE_INTEGRAL = 'integral';
export const CODE_INTEGRAL_NORM = 'integralNorm';
export const CODE_SPREAD = 'spread';
export const CODE_FLUOR = 'fluor';
export const CODE_BRILLIANCE = 'brilliance';
export const CODE_COLOR = 'color';
