var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import isNil from 'lodash/isNil';
import { ACLAssignee } from '@/security/entities/ACLAssignee';
import { B2B } from '../../b2b/entity/B2B';
export default class Project {
    id;
    title;
    type;
    exportedAt;
    createdAt;
    weight;
    solutionsCount;
    maxSolutionDiamonds;
    isLabGrown;
    maxYield;
    access;
    dataAccess;
    acl = [];
    b2b;
    file;
    cbr;
    solutions = [];
    expenses;
    next;
    constructor({ id, title, type, exportedAt, createdAt, file, weight, solutionsCount, maxYield, maxSolutionDiamonds, b2b, access, dataAccess, cbr, isLabGrown = false, expenses, next, acl, }) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.exportedAt = exportedAt;
        this.createdAt = createdAt;
        this.file = file;
        this.weight = weight;
        this.solutionsCount = !isNil(solutionsCount) ? solutionsCount : null;
        this.maxYield = !isNil(maxYield) ? maxYield : null;
        this.cbr = cbr;
        this.isLabGrown = isLabGrown;
        this.maxSolutionDiamonds = maxSolutionDiamonds;
        this.b2b = b2b ? new B2B(b2b) : null;
        this.access = access;
        this.dataAccess = dataAccess;
        this.expenses = expenses;
        this.next = next;
        this.acl = (acl ?? []).map((acl) => ({ dataAccess: acl.dataAccess, assignee: new ACLAssignee(acl.assignee) }));
    }
    get products() {
        if (!this.solutions) {
            return null;
        }
        if (this.solutions.length === 0) {
            return [];
        }
        return this.solutions.map((s) => s.products).flat();
    }
    setSolutions(solutions) {
        this.solutions = solutions;
    }
    setAccess = (access) => {
        this.access = access;
    };
}
__decorate([
    observable
], Project.prototype, "access", void 0);
__decorate([
    observable
], Project.prototype, "dataAccess", void 0);
__decorate([
    observable
], Project.prototype, "acl", void 0);
__decorate([
    observable
], Project.prototype, "solutions", void 0);
__decorate([
    computed
], Project.prototype, "products", null);
__decorate([
    action
], Project.prototype, "setSolutions", null);
__decorate([
    action
], Project.prototype, "setAccess", void 0);
