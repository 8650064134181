import Storage, { KEY_STEREO_MODE } from '@/common/services/Storage';
import { AUTOSTEREO_MODE, GLASSES_MODE } from '@/stereo/constants/mode';
import { isAutoStereoMode } from '@/stereo/helpers/mode';
export function toggleStereoMode() {
    const nextMode = isAutoStereoMode() ? GLASSES_MODE : AUTOSTEREO_MODE;
    Storage.saveObject(KEY_STEREO_MODE, nextMode, Storage.STORAGE_LOCAL);
    return nextMode;
}
export function setGlassesMode() {
    Storage.saveObject(KEY_STEREO_MODE, GLASSES_MODE, Storage.STORAGE_LOCAL);
}
export function setAutostereoMode() {
    Storage.saveObject(KEY_STEREO_MODE, AUTOSTEREO_MODE, Storage.STORAGE_LOCAL);
}
