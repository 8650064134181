import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'styles/index.less';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import { configure } from 'mobx';
import { configureLogger, logger } from '@cutwise/utils';
import Notifier from '@/common/services/notifier';
import { initSentry } from '@/common/services/sentry';
import { configurationStore } from '@/common/stores/ConfigurationStore';
import { netBoxStore } from '@/netbox/stores/NetBoxStore';
// TODO temporary disable, revert after release (Jule 2024)
// import { netBoxStore } from '@/netbox/stores/NetBoxStore';
import { StoreProvider } from '@/store';
import './icon.font.js';
import Root from './Root';
const BrowserSupport = lazy(() => import('@/common/components/BrowserSupport/BrowserSupport'));
configure({ isolateGlobalState: true });
configureLogger({
    prefix: 'CUTWISE',
});
initSentry();
// TODO temporary disable, revert after release (Jule 2024)
// netBoxStore.connectToNetBox();
// load constants as soon as possible, before App starts render
configurationStore.fetchServerConstants().then(netBoxStore.connectToNetBox);
ReactDOM.render(_jsx(Suspense, { fallback: null, children: _jsx(BrowserSupport, {}) }), document.getElementById('browser-support-container'));
ReactDOM.render(_jsx(ToastContainer, { hideProgressBar: true, limit: 4, transition: Zoom }), document.getElementById('notifications-container'));
ReactDOM.render(_jsx(StoreProvider, { children: _jsx(Suspense, { fallback: null, children: _jsx(Root, {}) }) }), document.getElementById('app'));
window.addEventListener('offline', () => {
    Notifier.warn('Internet connection is lost');
});
/*
 * Handle "UnhandledRejection" global event
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event#basic_error_logging
 * */
window.addEventListener('unhandledrejection', (e) => {
    logger.error('Unhandled rejection (promise: ', e.promise, ', reason: ', e.reason, ').');
    e.preventDefault();
    e.stopPropagation();
});
window.onunhandledrejection = (e) => {
    logger.error('Unhandled rejection (promise: ', e.promise, ', reason: ', e.reason, ').');
    e.preventDefault();
    e.stopPropagation();
};
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/sw.js')
        .then((reg) => logger.info(`SW registration succeeded. Scope is ${reg.scope}`))
        .catch((err) => logger.error(`SW registration failed with error ${err}`));
}
