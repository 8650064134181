var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { pureStructureFrom } from '@/common/helpers/pureStructureFrom';
import ConfirmationFactory from '@/common/services/ConfirmationFactory';
import Notifier from '@/common/services/notifier';
import ContactsAPI from '@/contacts/api/ContactsAPI';
import { ContactData } from '@/contacts/entity/ContactData';
class ContactsStore {
    contacts = [];
    contactsAPI = new ContactsAPI();
    initContactsStore = () => {
        this.getUserContacts();
    };
    getUserContacts = () => this.contactsAPI.getUserContacts().then((response) => {
        if (!response.content) {
            this.contacts = [];
            return;
        }
        this.contacts = response.content.map((contact) => new ContactData(contact));
    });
    createContact = (data) => this.contactsAPI.create(data).then((response) => {
        const newContact = new ContactData(response.content);
        this.contacts = [...this.contacts, newContact];
        return newContact;
    });
    editContact = (data) => this.contactsAPI.edit(data).then((response) => {
        const newContact = new ContactData(response.content);
        const nextContacts = pureStructureFrom(this.contacts);
        const targetIndex = nextContacts.findIndex(({ id }) => id === data.id);
        if (targetIndex !== -1) {
            nextContacts[targetIndex] = newContact;
        }
        this.contacts = nextContacts;
        return newContact;
    });
    deleteContact = (id) => {
        ConfirmationFactory.makeDeleteConfirmation('contact')
            .then(() => {
            this.contactsAPI.remove(id).then(() => {
                this.getUserContacts().then(() => {
                    Notifier.success('Contact was deleted.');
                });
            });
        })
            .catch(() => {
            /*
             * TODO
             * when close confirm model, Promise throw an error, probably we need to change this behavior
             * for now just do not do anything to avoid error logs in console
             * */
        });
    };
}
__decorate([
    observable
], ContactsStore.prototype, "contacts", void 0);
export const contactsStore = new ContactsStore();
export default ContactsStore;
