import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './MediaPlaceholder.less';
import Product from '../../../product/entity/Product';
import Diamond from '../../../product/entity/Diamond';
import ProductPlaceholder from '../ProductPlaceholder/ProductPlaceholder';
import Link, {NONE} from '../../../common/components/Link/Link';
import Rough from '../../../product/entity/Rough';
import {TYPE_ROUGH} from '../../../product/constants/productTypes';

export default class MediaPlaceholder extends PureComponent {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    href: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
    showIcon: PropTypes.bool,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    href: '',
    size: null,
    className: null,
    style: {},
    showIcon: true,
  };

  render() {
    let iconName = this.props.product.type;

    if (this.props.product instanceof Diamond) {
      iconName = this.props.product.parentCutShapeId;
    }
    if (this.props.product instanceof Rough) {
      iconName = TYPE_ROUGH;
    }

    const icon = <ProductPlaceholder shape={iconName} color="outline" />;
    let wrappedIcon = null;

    if (this.props.showIcon) {
      wrappedIcon = this.props.href ? <Link href={this.props.href} appearance={NONE}>{icon}</Link> : icon;
    }

    const style = { ...this.props.style };

    if (this.props.size) {
      style.height = `${this.props.size}px`;
      style.width = `${this.props.size}px`;
    }

    return (
      <div className={`media-in-list-placeholder ${this.props.className ? this.props.className : ''}`} style={style}>
        <div className="media-in-list-placeholder__content-wrapper">{wrappedIcon}</div>
      </div>
    );
  }
}
