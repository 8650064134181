var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { ROLE_CUSTOM_GRADER, ROLE_INTERNAL, ROLE_LABGROWN, ROLE_MANAGER, ROLE_METRICS_CONTROLLER, ROLE_NETBOX_USER, ROLE_RECUT_MANAGER, ROLE_RENDERER, ROLE_SUPER_ADMIN, } from '../constants/roles';
export const GO_TO_GRADING = 'goToGradingGranted';
export const GO_TO_PAIR_SORTING = 'goToPairSortingGranted';
export const COLLECTION_EDIT = 'collectionEditGranted';
export const PRODUCT_EDIT = 'productPageEditGranted';
export const BULK_PRODUCT_EDIT = 'bilkProductEditGranted';
export const NETBOX = 'netboxGranted';
export const LGD = 'lgdGranted';
export const MANAGER = 'managerGranted';
export const INTERNAL = 'internalGranted';
export const SHARE_ACCESS = 'shareAccessGranted';
export const ADMIN = 'adminGranted';
export const VIEW_PRODUCT_STATUS = 'viewProductStatusGranted';
export const RENDERER = 'rendererGranted';
export const RENDER_MANAGER = 'renderManagerGranted';
export const BASIS_GRANTED = 'basisGranted';
export const METRICS_CONTROLLER_GRANTED = 'metricsControllerGranted';
export const GRADE_PRODUCT = 'gradeProduct';
export const MANAGE_PROJECT = 'manageProject';
export const IMPORT_PRODUCTS = 'importProducts';
export const DELETE_SOLUTION = 'deleteSolution';
export const SHARE_PROJECT = 'shareProject';
export const SHARE_PRODUCT = 'shareProduct';
export const DELETE_PROJECT = 'deleteProject';
export const DELETE_PRODUCT = 'deleteProduct';
export const RENDER_PROJECT = 'projectRender';
export const DENY_IMPORT_UPLOAD = 'import:upload';
export const DENY_DELETE_SOLUTION = 'solution:delete';
export const DENY_SHARE_PROJECT = 'project:acl';
export const DENY_SHARE_PRODUCT = 'product:acl';
export const DENY_DELETE_PROJECT = 'project:delete';
export const DENY_DELETE_PRODUCT = 'product:delete';
export const DENY_EDIT_PRODUCT = 'product:edit';
export const DENY_RENDER_PROJECT = 'project:render';
const ACTION_GRANTERS = {
    [METRICS_CONTROLLER_GRANTED]: ({ roles }) => Boolean(roles?.includes(ROLE_METRICS_CONTROLLER)),
    [BASIS_GRANTED]: ({ roles, b2bId }) => Boolean(roles?.includes(ROLE_INTERNAL) || b2bId === 59),
    [GO_TO_GRADING]: ({ roles }) => Boolean(roles?.includes(ROLE_CUSTOM_GRADER)),
    [GO_TO_PAIR_SORTING]: ({ roles }) => Boolean(roles?.includes(ROLE_RECUT_MANAGER)),
    [COLLECTION_EDIT]: ({ id }, options) => Boolean(options?.checkingCollection?.user?.id === id),
    [PRODUCT_EDIT]: (user, options) => {
        if (!options?.product?.seller) {
            return false;
        }
        if (!ACTION_GRANTERS[BULK_PRODUCT_EDIT](user)) {
            return false;
        }
        return options?.product.sellerId === user.b2bId;
    },
    [NETBOX]: ({ roles }) => Boolean(roles?.includes(ROLE_NETBOX_USER)),
    [LGD]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER) && roles?.includes(ROLE_LABGROWN)),
    [MANAGER]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER)),
    [INTERNAL]: ({ roles }) => Boolean(roles?.includes(ROLE_INTERNAL)),
    [SHARE_ACCESS]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER) || roles?.includes(ROLE_RECUT_MANAGER)),
    [ADMIN]: ({ roles }) => Boolean(roles?.includes(ROLE_SUPER_ADMIN)),
    [VIEW_PRODUCT_STATUS]: (user, options) => (ACTION_GRANTERS[MANAGER](user) && ACTION_GRANTERS[PRODUCT_EDIT](user, { product: options?.product })) ||
        ACTION_GRANTERS[ADMIN](user),
    [RENDERER]: ({ roles }) => Boolean(roles?.includes(ROLE_RENDERER)),
    [RENDER_MANAGER]: (user) => ACTION_GRANTERS[RENDERER](user) && ACTION_GRANTERS[MANAGER](user),
    [GRADE_PRODUCT]: (user, options) => ACTION_GRANTERS[PRODUCT_EDIT](user, { product: options?.product }),
    [MANAGE_PROJECT]: ({ b2bId }, options) => Boolean(options?.project?.b2b?.id === b2bId),
    [IMPORT_PRODUCTS]: ({ deny }) => !deny?.includes(DENY_IMPORT_UPLOAD),
    [DELETE_SOLUTION]: ({ deny }) => !deny?.includes(DENY_DELETE_SOLUTION),
    [SHARE_PROJECT]: ({ deny }) => !deny?.includes(DENY_SHARE_PROJECT),
    [SHARE_PRODUCT]: ({ deny }) => !deny?.includes(DENY_SHARE_PRODUCT),
    [DELETE_PROJECT]: ({ deny }) => !deny?.includes(DENY_DELETE_PROJECT),
    [DELETE_PRODUCT]: ({ deny }) => !deny?.includes(DENY_DELETE_PRODUCT),
    [RENDER_PROJECT]: ({ deny }) => !deny?.includes(DENY_RENDER_PROJECT),
    [BULK_PRODUCT_EDIT]: ({ deny }) => !deny?.includes(DENY_EDIT_PRODUCT),
};
export default class SecurityManager {
    user;
    setUser(user) {
        this.user = user;
    }
    isGranted(action, options = null) {
        if (!this.user?.roles) {
            return false;
        }
        return ACTION_GRANTERS[action](this.user, options);
    }
}
__decorate([
    observable.ref
], SecurityManager.prototype, "user", void 0);
export const securityManager = new SecurityManager();
