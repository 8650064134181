export var ViewTemplateType;
(function (ViewTemplateType) {
    ViewTemplateType[ViewTemplateType["Colorless"] = 1] = "Colorless";
    ViewTemplateType[ViewTemplateType["Fancy"] = 2] = "Fancy";
    ViewTemplateType[ViewTemplateType["Jewelry"] = 3] = "Jewelry";
    ViewTemplateType[ViewTemplateType["Rough"] = 4] = "Rough";
})(ViewTemplateType || (ViewTemplateType = {}));
export const VIEW_TEMPLATE_TYPE_COLORLESS = ViewTemplateType.Colorless;
export const VIEW_TEMPLATE_TYPE_FANCY = ViewTemplateType.Fancy;
export const VIEW_TEMPLATE_TYPE_JEWELRY = ViewTemplateType.Jewelry;
export const VIEW_TEMPLATE_TYPE_ROUGH = ViewTemplateType.Rough;
