import isEmpty from 'lodash/isEmpty';
import {dictionaryRepository} from '@/common/repositories/DictionaryRepository';

export default class FiltersNormalizer {
  /**
   * @param {Object} data JSON
   * @return {Object} JSON
   */
  static normalize(data) {
    if (data.createdAt && data.createdAt.from && data.createdAt.from.format) {
      data.createdAt.from = data.createdAt.from.format('yyyy-MM-dd');
    }

    if (data.createdAt && data.createdAt.to && data.createdAt.to.format) {
      data.createdAt.to = data.createdAt.to.format('yyyy-MM-dd');
    }

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof Object && !Array.isArray(value)) {
        data[key] = FiltersNormalizer.normalize(value);
      }

      if (data[key] === false || data[key] === true) {
        data[key] = Number(data[key]);
      }

      if (((value instanceof Object || Array.isArray(value)) && isEmpty(value)) || value === null || value === undefined || value === '') {
        delete data[key];
      }
    });

    if (data.fireRG) {
      data['fire.rg'] = data.fireRG;
      delete data.fireRG;
    }

    if (data.scintillationRG) {
      data['scintillation.rg'] = data.scintillationRG;
      delete data.scintillationRG;
    }

    if (data.brightnessRG) {
      data['brightness.rg'] = data.brightnessRG;
      delete data.brightnessRG;
    }

    if (data.brightnessRG2) {
      data['brightness.rg2'] = data.brightnessRG2;
      delete data.brightnessRG2;
    }

    if (data.brightnessDZ) {
      data['brightness.dz'] = data.brightnessDZ;
      delete data.brightnessDZ;
    }

    if (data.opticalPerformanceRG) {
      data['opticalPerformance.rg'] = data.opticalPerformanceRG; // todo: don't forget to change filter attr later
      delete data.opticalPerformanceRG;
    }

    if (data.shapeNormalizedPerformance) {
      data['integral.smc'] = data.shapeNormalizedPerformance;
      delete data.shapeNormalizedPerformance;
    }

    if (data.fluorGrade) {
      data['fluor.grade'] = data.fluorGrade;
      delete data.fluorGrade;
    }

    if (data.fluorColor) {
      data['fluor.color'] = data.fluorColor;
      delete data.fluorColor;
    }

    if (data.inclusions && Array.isArray(data.inclusions)) {
      ['openInc', 'whiteInc', 'blackInc'].forEach((key) => {
        data.inclusions.forEach((title) => {
          let item = dictionaryRepository.findItemInDictionaryByTitle(key, title);

          if (item) {
            data[key] = Array.isArray(data[key]) ? data[key].concat(item.id) : [item.id];
          }
        });
      });

      delete data.inclusions;
    }

    return data;
  }
}
