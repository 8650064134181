import { v4 as uuidv4 } from 'uuid';
import Auth from './middleware/Auth';
import ContentType from './middleware/ContentType';
import HandleErrors from './middleware/HandleErrors';
import QueryBuilder from './QueryBuilder';
import QueryResult from './QueryResult.ts';
import URLBuilder from './URLBuilder';
import SecurityCheck from './middleware/SecurityCheck';
import { netboxifyURL } from '../../netbox/helpers/netbox.ts';
import AppError, {DESCRIPTION_DEFAULT, TITLE_DEFAULT} from '../state/AppError';
import Notifier from '../services/notifier';

export const NETBOX_KEY = 'netbox';
export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_PATCH = 'PATCH';
export const METHOD_DELETE = 'DELETE';
export const METHOD_HEAD = 'HEAD';
export const METHOD_COPY = 'COPY';
export const METHOD_LINK = 'LINK';
export const METHOD_UNLINK = 'UNLINK';

export const HEADER_X_TOTAL_COUNT = 'X-Total-Count';
export const HEADER_X_PRODUCTS_COUNT = 'X-Products-Count';
export const HEADER_X_TOTAL_CASH_EXPENSES = 'X-Total-Cash-Expenses';
export const HEADER_X_TOTAL_CASH_ENROLLMENTS = 'X-Total-Cash-Enrollments';
export const HEADER_X_TOTAL_BONUSES_EXPENSES = 'X-Total-Bonuses-Expenses';
export const HEADER_X_TOTAL_BONUSES_ENROLLMENTS = 'X-Total-Bonuses-Enrollments';
export const HEADER_X_EXPENSES = 'X-Expenses';
export const HEADER_X_NEXT_PAYMENT = 'X-NextPayment';
export const HEADER_X_NETBOX = 'X-NetBox';

function prepareQueryContent(data) {
  if (data instanceof Blob) {
    return data;
  }

  let result = JSON.parse(JSON.stringify(data));

  // for "null", "0", "''", "undefined"
  if (!result) {
    return result;
  }

  if (typeof result === 'string') {
    return netboxifyURL(result);
  }

  if (Array.isArray(data)) {
    result = data.map(item => prepareQueryContent(item));
  } else {
    const keys = Object.keys(result);

    if (keys.length > 0) {
      keys.forEach((key) => {
        result[key] = prepareQueryContent(result[key]);
      });
    }
  }

  return result;
}

export default class CutwiseAPIClient {
  /**
   * @return {QueryBuilder}
   */
  static createQueryBuilder() {
    return new QueryBuilder();
  }

  /**
   * @param {Query} query
   * @return {Promise<QueryResult>}
   */
  static sendQuery(query) {
    query.addRequestMiddleware(SecurityCheck);
    query.addRequestMiddleware(Auth);
    query.addRequestMiddleware(ContentType);

    query.addResponseMiddleware(HandleErrors(query.suppressErrorNotifications));

    return new Promise((resolve, reject) => {
      query.requestMiddlewares.forEach((middleware) => middleware(query));

      const notificationId = uuidv4();

      const requestParams = {
        method: query.method,
        cache: 'no-cache',
        body: query.serializer.serialize(query.requestBody),
        headers: query.headers,
      };

      const makeRequest = resolveFetch => fetch(URLBuilder.buildUrl(query.path, query.queryStringParams), requestParams)
        .then((res) => {
          Notifier.dismissNotification(notificationId);

          resolveFetch(res);
        })
        .catch((err) => {
          if (!window.navigator.onLine) {
            window.addEventListener('online', () => makeRequest(resolveFetch));
          } else {
            throw err;
          }
        });

      const requestPromise = new Promise((resolveFetch) => {
        let requestTimeout;
        let retryTimeout;

        function requestWrapperFunction() {
          makeRequest(resolveFetch)
            .catch((err) => {
              if (!query.repeatRequestTimeout) {
                clearTimeout(requestTimeout);
                clearTimeout(retryTimeout);

                reject(new AppError(null, err.message, DESCRIPTION_DEFAULT));

                return;
              }

              retryTimeout = setTimeout(() => {
                requestWrapperFunction();
              }, query.repeatRequestRetryTimeout);

              // Be sure that timeout for request initialized only once
              if (!requestTimeout) {
                Notifier.warn(
                  'Internet connection problems. Trying to connect.',
                  '',
                  { delay: query.repeatRequestTimeout, toastId: notificationId },
                );

                requestTimeout = setTimeout(() => {
                  clearTimeout(requestTimeout);
                  clearTimeout(retryTimeout);

                  reject(new AppError(null, TITLE_DEFAULT, DESCRIPTION_DEFAULT));
                }, query.repeatRequestTimeout);
              }
            });
        }

        requestWrapperFunction();
      });

      requestPromise.then((res) => {
        let decodingPromise;

        switch (query.responseType) {
          case 'text':
            decodingPromise = res.text();
            break;
          case 'blob':
            decodingPromise = res.blob();
            break;
          case 'json':
          default:
            decodingPromise = res.json();
            break;
        }

        decodingPromise
          .catch(() => null)
          .then((json) => {
            const queryResult = new QueryResult();
            queryResult.networkResponse = res;
            queryResult.content = prepareQueryContent(json);

            query.result = queryResult;

            // reverse is really needed here because we have to call default middlewares first
            query.responseMiddlewares.reverse().forEach((middleware) => middleware(query));

            if (query.hydrator) {
              queryResult.content = query.hydrator(queryResult.content);
            }

            resolve(queryResult);
          })
          .catch(reject); // pass error to outer promise
      });
    });
  }
}
