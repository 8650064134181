import {
  observable, action, computed, reaction,
} from 'mobx';
import Collection, {
  COLLECTION_TYPE_DIAMOND,
  COLLECTION_TYPE_JEWELRY,
  COLLECTION_TYPE_ROUGH,
} from '../entity/Collection';
import CollectionsAPI from '../api/CollectionsAPI';
import {TYPE_DIAMOND_COLORLESS} from '../../product/constants/productTypes';
import token from '@/user/services/token';
import Notifier from '../../common/services/notifier';
import {VIEW_TYPE_GRID} from '../../product/entity/ProductViewSettings';
import {MEDIA_SIZE_MEDIUM} from '../../media/constants/sizeCodes';
import {viewTemplateRepository} from '../repository/ViewTemplateRepository';
import ProductFactory from '../../product/entity/ProductFactory';
import {userStore} from '@/user/stores/UserStore';
import {appStore} from "@/common/stores/AppStore";

class CollectionsStore {
  /**
   * @type {Object<{ [key: number]: Collection[] }>}
   */
  @observable
  collectionsByType = {
    [COLLECTION_TYPE_DIAMOND]: null,
    [COLLECTION_TYPE_JEWELRY]: null,
    [COLLECTION_TYPE_ROUGH]: null,
  };

  @observable
  /**
   * @type {?Collection}
   */
  activeCollection = null;

  collectionsPromises = {};

  /**
   * @param {AppStore} appStore
   */
  constructor(appStore) {
    this.appStore = appStore;
    this.collectionsAPI = new CollectionsAPI();

    this.initCollections();

    reaction(
      () => this.activeProductTypeContext,
      this.initCollections,
    );

    reaction(
      () => userStore?.user,
      (user) => {
        if (!user?.isAnonymous) {
          this.initCollections()
        }
      },
    );
  }

  @computed
  get activeProductTypeContext() {
    return this.appStore.productTypeContext || TYPE_DIAMOND_COLORLESS;
  }

  @computed
  get collections() {
    const cType = Collection.getTypeByProductType(this.activeProductTypeContext);

    if (!this.collectionsByType[cType]) {
      return null;
    }

    const filteredCollectionsByType = this.collectionsByType[cType].filter(c => c.type === cType);

    return filteredCollectionsByType.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB - dateA;
    });
  }

  @action
  initCollections = () => {
    if (token.isAuthorized) {
      this.fetchCollections();
    }

    if (!this.collections || !this.collections.find(c => c === this.activeCollection)) {
      this.activeCollection = null;
    }
  };

  @action
  fetchCollections() {
    const collectionType = Collection.getTypeByProductType(this.activeProductTypeContext);

    if (this.collectionsPromises[collectionType]) {
      return;
    }

    this.collectionsPromises[collectionType] = this.collectionsAPI.fetchOwned(collectionType).then((res) => {
      if (!res.ok && res.content && res.content.message && res.status !== 404) {
        Notifier.error(res.content.message);
      }

      const collections = res.content ? res.content.map(d => new Collection(d, this.activeProductTypeContext)) : null;
      this.collectionsByType[collectionType] = collections && collections.length > 0 ? [...collections.filter(c => c.updatedAt !== null), ...collections.filter(c => c.updatedAt === null)] : [];
    });
  }

  /**
   * @param {string} title
   * @param {?Product} product
   * @return {Promise}
   */
  @action
  createNewCollection = (title, product = null) => {
    return this.collectionsAPI
      .create({
        public: true,
        title: title,
        type: Collection.getTypeByProductType(this.activeProductTypeContext),
        settings: {
          type: VIEW_TYPE_GRID,
          mediaSize: MEDIA_SIZE_MEDIUM,
          viewTemplate: viewTemplateRepository.getDefaultViewTemplateId(this.activeProductTypeContext),
          isAutoplayEnabled: true,
        },
      })
      .then(
        action((res) => {
          const collection = new Collection(res.data, this.activeProductTypeContext);
          if (product) {
            this.addProductToCollection(product, collection);
          }

          const collectionType = Collection.getTypeByProductType(this.activeProductTypeContext);
          this.collectionsByType[collectionType].unshift(collection);

          this.activeCollection = collection;
        }),
      )
      .catch(this.appStore.setError);
  };

  /**
   * @param {Product} product
   * @param {Collection} collection
   */
  @action
  addProductToCollection = (product, collection) => {
    if (!collection.products) {
      collection.products = [];
    }
    collection.products.push(product);

    return this.collectionsAPI.addProductToCollection(product, collection).then(() => {
      if (product.b2bSid) {
        Notifier.success(`${product.b2bSid} was added to collection "${collection.title}"`);
      }
    });
  };

  /**
   * @param {Product} product
   * @param {Collection} collection
   */
  @action
  removeProductFromCollection = (product, collection) => {
    collection.products = collection.products.filter(p => p.id !== product.id);

    return this.collectionsAPI.removeProductFromCollection(product, collection).then(() => {
      if (product.b2bSid) {
        Notifier.success(`${product.b2bSid} was removed from collection "${collection.title}"`);
      }
    });
  };

  /**
   * @param {Collection} c
   */
  @action
  toggleActive = (c) => {
    if (this.activeCollection === c) {
      this.activeCollection = null;
    } else {
      this.setActiveCollection(c);
    }
  };

  @action
  remove = (c) => {
    const { title } = c;

    if (this.activeCollection === c) {
      this.activeCollection = null;
    }

    const cType = Collection.getTypeByProductType(this.activeProductTypeContext);

    if (!this.collectionsByType[cType]) {
      return;
    }

    this.collectionsByType[cType] = this.collectionsByType[cType].filter(cItem => cItem.id !== c.id);

    this.collectionsAPI.remove(c.id).then(
      action((res) => {
        if (!res.ok && res.data && res.data.message && res.status !== 404) {
          this.collectionsByType[cType].push(c);
          Notifier.error(res.data.message);
          return;
        }

        Notifier.success(`Collection ${title} was removed`);
      }),
    );
  };

  /**
   * @param {Collection} c
   */
  @action
  setActiveCollection = (c) => {
    this.activeCollection = c;
    this.collectionsAPI.touchCollection(c);
  };

  /**
   * @param {Collection} collection
   * @param {[number]} pIds
   */
  @action
  wishAll = (collection, pIds) => {
    return this.collectionsAPI.wishAll(collection.id, pIds).then(
      action((res) => {
        if (!res.ok && res.data && res.data.message && res.status !== 404) {
          Notifier.error(res.data.message);

          return res;
        }

        pIds.forEach((pid) => {
          const product = collection.products.find(p => p.id === pid);
          if (!product) {
            collection.products.push(ProductFactory.make(this.appStore.productTypeContext, { id: pid }));
          }
        });

        return res;
      }),
    );
  };

  /**
   * @param {Collection} collection
   */
  @action
  addCollection(collection) {
    const collectionType = Collection.getTypeByProductType(this.appStore.productTypeContext);

    if (!this.collectionsByType[collectionType]) {
      this.collectionsByType[collectionType] = [];
    }

    this.collectionsByType[collectionType].push(collection);
  }
}

export const collectionsStore = new CollectionsStore(appStore)
export default CollectionsStore
