import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.less';

export const ALERT_WARNING = 'warning';
export const ALERT_DANGER = 'danger';
export const ALERT_SUCCESS = 'success';

const TYPE_CLASS_MAP = {
  [ALERT_WARNING]: styles.alertWarning,
  [ALERT_DANGER]: styles.alertDanger,
  [ALERT_SUCCESS]: styles.alertSuccess,
};

export default class Alert extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf([ALERT_WARNING, ALERT_DANGER, ALERT_SUCCESS]),
    title: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    className: '',
    type: ALERT_WARNING,
  };

  render() {
    return (
      <div className={`${styles.alert} ${TYPE_CLASS_MAP[this.props.type]} ${this.props.className}`}>
        {this.props.title && <strong>{`${this.props.title} `}</strong>}
        {this.props.children}
      </div>
    );
  }
}
