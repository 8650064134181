import memoize from 'lodash/memoize';

class ComboGradient {
  static rgbAt(val) {
    if (val < 0.5) {
      return `rgba(${180 + (1 - val) * 50},72,72)`;
    }
    return `rgba(58,${150 + val * 50},137)`;
  }
}

ComboGradient.rgbAt = memoize(ComboGradient.rgbAt);

export default ComboGradient;
