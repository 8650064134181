import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import URLProvider from '../../../common/services/URLProvider';
import Link from '../../../common/components/Link/Link';
import Project from '../../entity/Project';

@observer
export default class ProjectLink extends Component {
  static propTypes = {
    project: PropTypes.instanceOf(Project).isRequired,
    title: PropTypes.string.isRequired,
    external: PropTypes.bool,
  };

  static defaultProps = {
    external: false,
  };

  render() {
    const {
      project, external, title, ...rest
    } = this.props;

    return (
      <Link
        external={external}
        to={URLProvider.solutionsPage(project)}
        aria-label="Project Link"
        title={title}
        {...rest}
      >
        {title}
      </Link>
    );
  }
}
