export var DefaultViewTemplate;
(function (DefaultViewTemplate) {
    DefaultViewTemplate[DefaultViewTemplate["Colorless"] = 16] = "Colorless";
    DefaultViewTemplate[DefaultViewTemplate["Fancy"] = 17] = "Fancy";
    DefaultViewTemplate[DefaultViewTemplate["Rough"] = 15] = "Rough";
    DefaultViewTemplate[DefaultViewTemplate["Jewelry"] = 15] = "Jewelry";
    DefaultViewTemplate[DefaultViewTemplate["ColorlessEdit"] = 18] = "ColorlessEdit";
    DefaultViewTemplate[DefaultViewTemplate["FancyEdit"] = 19] = "FancyEdit";
    DefaultViewTemplate[DefaultViewTemplate["RoughEdit"] = 20] = "RoughEdit";
    DefaultViewTemplate[DefaultViewTemplate["JewelryEdit"] = 20] = "JewelryEdit";
})(DefaultViewTemplate || (DefaultViewTemplate = {}));
