export function getEnvironment() {
    let environment = 'production';
    if (window.location.host === 'develop.cutwise.com') {
        environment = 'develop';
    }
    else if (window.location.host === 'staging.cutwise.com') {
        environment = 'staging';
    }
    else if (window.location.host === 'cutwise.local') {
        environment = 'local';
    }
    return environment;
}
