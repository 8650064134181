import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AnimatedDropdown.less';

export default class AnimatedDropdown extends Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.node,
    className: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    label: null,
    className: '',
  };

  contentRef = React.createRef();

  state = {
    opened: false,
    contentHeight: 0,
  };

  componentDidMount() {
    this.setState({
      contentHeight: this.getContentHeight(),
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   * @return {number}
   */
  getContentHeight() {
    return this.contentRef.current.scrollHeight + this.contentRef.current.offsetHeight;
  }

  handleClickOutside = (event) => {
    event.stopPropagation();

    if (this.state.opened === true && this.contentRef.current && !this.contentRef.current.contains(event.target)) {
      this.close();
    }
  };

  toggle = (event) => {
    event.stopPropagation();

    if (this.state.opened === true) {
      this.close();
      return;
    }

    this.setState({
      contentHeight: this.getContentHeight(),
      opened: true,
    });

    document.addEventListener('click', this.handleClickOutside);
  };

  close = () => {
    this.setState({
      opened: false,
    });

    document.removeEventListener('click', this.handleClickOutside);
  };

  render() {
    return (
      <div className={`${styles.animatedDropdown} ${this.props.className}`}>
        <div className={`${styles.animatedDropdownLabel} ${this.state.opened ? styles.animatedDropdownLabelOpened : ''}`} role="button" onClick={this.toggle}>
          <div className={styles.animatedDropdownLabelLeft}>{this.props.label}</div>
          <span className={`${styles.animatedDropdownTriangle} ${this.state.opened ? styles.animatedDropdownTriangleOpened : ''}`} />
        </div>
        <div className={`${styles.animatedDropdownContent} ${this.state.opened === false ? styles.animatedDropdownContentClosed : ''}`} style={{ maxHeight: this.state.contentHeight }}>
          <div className={styles.animatedDropdownMenuContainer} ref={this.contentRef} onClick={this.close}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
