var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import Notifier from '@/common/services/notifier';
import { OrderStatus } from '@/conversion/constants/status';
import { ConversionOrder } from '@/conversion/entities/ConversionOrder';
import ConversionAPI from '../api/ConversionAPI';
const FETCH_NEW_ORDERS_LIST_INTERVAL = 10000;
export default class ConversionStore {
    isCreateModalOpen = false;
    isFetchingOrders = false;
    isCreatingOrder = false;
    orderList = [];
    filter = {
        filename: '',
    };
    conversionAPI;
    requestOrderInterval;
    constructor() {
        this.conversionAPI = new ConversionAPI();
    }
    get isOrderListEmpty() {
        return this.orderList.length === 0;
    }
    get filteredOrderList() {
        return this.orderList.filter((order) => order.name.toLowerCase().includes(this.filter.filename.toLowerCase()));
    }
    fetchOrderList = async () => {
        this.isFetchingOrders = true;
        try {
            await this.getOrdersListRequest();
            clearInterval(this.requestOrderInterval);
            this.requestOrderInterval = setInterval(() => this.refetchOrdersListRequest(), FETCH_NEW_ORDERS_LIST_INTERVAL);
        }
        catch (e) {
            Notifier.error(e.message);
        }
        this.isFetchingOrders = false;
    };
    createOrder = (data) => {
        this.isCreatingOrder = true;
        return this.conversionAPI
            .createOrder(data)
            .then(() => this.fetchOrderList())
            .finally(() => {
            this.isCreatingOrder = false;
        });
    };
    openCreateModal = () => {
        this.isCreateModalOpen = true;
    };
    closeCreateModal = () => {
        this.isCreateModalOpen = false;
    };
    setConversionFilter = (filter) => {
        this.filter = {
            ...this.filter,
            ...filter,
        };
    };
    dispose = () => {
        clearInterval(this.requestOrderInterval);
    };
    getOrdersListRequest = () => this.conversionAPI.getOrderList().then((res) => {
        if (!res?.content) {
            return;
        }
        this.orderList = res.content.map((order) => new ConversionOrder(order));
    });
    refetchOrdersListRequest = async () => {
        // Check if at least one order has "Processing" status, if yes re-fetch list, if not - do nothing
        if (this.orderList?.some((order) => order.status === OrderStatus.Processing)) {
            await this.getOrdersListRequest();
        }
    };
}
__decorate([
    observable
], ConversionStore.prototype, "isCreateModalOpen", void 0);
__decorate([
    observable
], ConversionStore.prototype, "isFetchingOrders", void 0);
__decorate([
    observable
], ConversionStore.prototype, "isCreatingOrder", void 0);
__decorate([
    observable
], ConversionStore.prototype, "orderList", void 0);
__decorate([
    observable
], ConversionStore.prototype, "filter", void 0);
__decorate([
    computed
], ConversionStore.prototype, "isOrderListEmpty", null);
__decorate([
    computed
], ConversionStore.prototype, "filteredOrderList", null);
__decorate([
    action
], ConversionStore.prototype, "fetchOrderList", void 0);
__decorate([
    action
], ConversionStore.prototype, "createOrder", void 0);
__decorate([
    action
], ConversionStore.prototype, "openCreateModal", void 0);
__decorate([
    action
], ConversionStore.prototype, "closeCreateModal", void 0);
__decorate([
    action
], ConversionStore.prototype, "setConversionFilter", void 0);
export const conversionStore = new ConversionStore();
