var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable, reaction } from 'mobx';
import { pureStructureFrom } from '@/common/helpers/pureStructureFrom';
import LocalStorageDriver from '@/common/services/session/LocalStorageDriver';
import UserSessionDataManager from '@/common/services/session/UserSessionDataManager';
import { appStore } from '@/common/stores/AppStore';
import { COMPARISON_LIST_STORAGE_KEY, EMPTY_COMPARISON_STATE } from '@/comparison/constants/context';
import { getCompareContextByType } from '@/comparison/helpers/getCompareContextByType';
import ComparisonListSerializer from '../serializers/ComparisonListSerializer';
class ComparisonStore {
    // Data structure to store all added products to compare
    productMap = pureStructureFrom(EMPTY_COMPARISON_STATE);
    appStore;
    userSessionDataManager;
    constructor(appStore) {
        this.appStore = appStore;
        // initialize storage
        this.userSessionDataManager = new UserSessionDataManager(new LocalStorageDriver());
        this.userSessionDataManager.get(COMPARISON_LIST_STORAGE_KEY).then(this.syncProductMapWithJSON);
        this.userSessionDataManager.onKeyChange(COMPARISON_LIST_STORAGE_KEY, this.syncProductMapWithJSON);
        reaction(() => this.overallCountOfProducts, () => {
            this.userSessionDataManager.set(COMPARISON_LIST_STORAGE_KEY, ComparisonListSerializer.serialize(this.productMap));
        });
    }
    get overallCountOfProducts() {
        let overallCount = 0;
        Object.keys(this.productMap).forEach((productTypeKey) => {
            const type = this.productMap[getCompareContextByType(productTypeKey)];
            overallCount += type ? type.length : 0;
        });
        return overallCount;
    }
    get products() {
        return this.productMap[getCompareContextByType(this.appStore.productTypeContext)];
    }
    get productIds() {
        return this.products?.map((item) => item.id) ?? [];
    }
    get productsCount() {
        return this.products ? this.products.length : 0;
    }
    syncProductMapWithJSON = (jsonProductList) => {
        const deserializedProductList = ComparisonListSerializer.deserialize(jsonProductList);
        this.productMap = { ...pureStructureFrom(EMPTY_COMPARISON_STATE), ...deserializedProductList };
    };
    toggleProduct = (product) => {
        if (this.isExist(product)) {
            this.removeProduct(product);
        }
        else {
            this.addProduct(product);
        }
    };
    addProduct(product) {
        if (!this.isExist(product)) {
            this.productMap[getCompareContextByType(product.type)].push(product);
        }
    }
    removeProduct(product) {
        this.removeProductById(product.id);
    }
    removeProductById(id) {
        Object.keys(this.productMap).forEach((type) => {
            const context = getCompareContextByType(type);
            if (this.productMap[context]) {
                const index = this.productMap[context].findIndex((item) => item.id === id);
                if (index === -1) {
                    return;
                }
                this.productMap[type].splice(index, 1);
            }
        });
    }
    clearCompare() {
        this.productMap[getCompareContextByType(this.appStore.productTypeContext)] = [];
    }
    isExist = (product) => this.productMap[getCompareContextByType(product.type)]?.some((item) => item.id === product.id);
}
__decorate([
    observable
], ComparisonStore.prototype, "productMap", void 0);
__decorate([
    action
], ComparisonStore.prototype, "syncProductMapWithJSON", void 0);
__decorate([
    action
], ComparisonStore.prototype, "toggleProduct", void 0);
__decorate([
    action
], ComparisonStore.prototype, "addProduct", null);
__decorate([
    action
], ComparisonStore.prototype, "removeProduct", null);
__decorate([
    action
], ComparisonStore.prototype, "removeProductById", null);
__decorate([
    action
], ComparisonStore.prototype, "clearCompare", null);
export const comparisonStore = new ComparisonStore(appStore);
export default ComparisonStore;
