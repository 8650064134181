import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TextField.less';
import Button, {SIZE_XSMALL} from '../../Button/Button';
import Icon from '../../Icon/Icon';

export default class TextField extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string,
    normalize: PropTypes.func,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
    maxLength: PropTypes.number,
    modifier: PropTypes.string,
    defaultValue: PropTypes.string,
    onClear: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    onKeyDown: PropTypes.func,
  };

  static defaultProps = {
    value: undefined /* "undefined" is important here to ensure uncontrolled inputs behavior */,
    onChange: null,
    onBlur: null,
    className: '',
    name: '',
    normalize: null,
    prefix: '',
    postfix: '',
    maxLength: null,
    modifier: '',
    defaultValue: null,
    onClear: null,
    autoFocus: false,
    placeholder: null,
    onKeyDown: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value === nextProps.value) {
      return null;
    }

    return {
      value: nextProps.value,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };

    if (this.props.normalize && this.props.onChange) {
      this.onChangeWrapper = evt => this.props.onChange(evt, this.props.name, this.props.normalize);
    } else if (this.props.onChange) {
      this.onChangeWrapper = evt => this.props.onChange(evt, this.props.name);
    }

    if (this.props.normalize && this.props.onBlur) {
      this.onBlurWrapper = evt => this.props.onBlur(evt, this.props.name, this.props.normalize);
    } else if (this.props.onBlur) {
      this.onBlurWrapper = evt => this.props.onBlur(evt, this.props.name);
    }
  }

  handleKeyPress = (e) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (this.onChangeWrapper) {
      this.onChangeWrapper(e);
    }
    if (this.onBlurWrapper) {
      this.onBlurWrapper(e);
    }
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      value, onChange, onBlur, normalize, className, name, prefix, postfix, modifier, defaultValue, onClear, ...rest
    } = this.props;

    let sanitizedValue = value;
    if ((value === null || value === undefined) && defaultValue === null) {
      sanitizedValue = '';
    }

    const inputProps = {
      className: `
        form-control
        ${modifier ? `form-control--${modifier}` : ''}
        ${prefix ? 'form-control--prefixed' : ''}
        ${postfix ? 'form-control--postfixed' : ''}
      `,
      name: name || '',
      ...rest,
    };

    if (this.onBlurWrapper) {
      inputProps.defaultValue = sanitizedValue;
      inputProps.onBlur = this.onBlurWrapper;
      inputProps.key = sanitizedValue;
    }

    if (this.onChangeWrapper) {
      inputProps.value = sanitizedValue;
      inputProps.onChange = this.onChangeWrapper;
    }

    return (
      <div className={`form-control-wrapper ${className || ''}`}>
        {prefix && <span className="form-control-prefix">{prefix}</span>}
        {onClear && this.props.value && (
          <div className={styles.textFieldCloseButtonWrapper}>
            <Button
              color="transparent"
              onClick={onClear}
              size={SIZE_XSMALL}
              withIcon
            >
              <Icon name="close" />
            </Button>
          </div>
        )}
        <input
          {...inputProps}
          onKeyPress={this.handleKeyPress}
          placeholder={this.props.placeholder}
          onKeyDown={this.props.onKeyDown}
        />
        {postfix && <span className="form-control-postfix">{postfix}</span>}
      </div>
    );
  }
}
