import React, { Component } from 'react';
import styles from './PlayerErrorBoundary.less';

export default class PlayerErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div className={styles.playerErrorMessage}>Media playback error</div>;
    }

    return this.props.children;
  }
}
