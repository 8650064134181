import PropTypes from 'prop-types';
import React from 'react';
import {toast} from 'react-toastify';

/**
 * @typedef {Object} NotificationMessageParams
 * @property {number} delay
 * @property {?string|number} toastId
 */

const NotificationContent = ({title, message}) => {
  return (
    <div>
      {title && <div><strong>{title}</strong></div>}
      {message && <div>{message}</div>}
    </div>
  );
};

NotificationContent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

NotificationContent.defaultProps = {
  title: '',
  message: '',
};

const TOAST_IDS_MAP = {};

// todo: https://github.com/fkhadra/react-toastify/issues/402
export default class Notifier {
  /**
   * @param {string} message
   * @param {string} title
   * @param {NotificationMessageParams} params
   */
  static success(message, title = '', { delay = 5000, toastId = null} = {}) {
    Notifier.sendNotification(toast.success, message, title, delay, toastId);
  }

  /**
   * @param {string} message
   * @param {string} title
   * @param {NotificationMessageParams} params
   */
  static warn(message, title = '', { delay = 5000, toastId = null} = {}) {
    Notifier.sendNotification(toast.warn, message, title, delay, toastId);
  }

  /**
   * @param {string} message
   * @param {string} title
   * @param {NotificationMessageParams} params
   */
  static error(message, title = '', { delay = 0, toastId = null} = {}) {
    if (!message) {
      return;
    }

    Notifier.sendNotification(toast.error, message, title, delay, toastId);
  }

  /**
   * @private
   * @param {function} sender
   * @param {string} message
   * @param {string} title
   * @param {number} delay
   * @param {?string|number} toastId
   */
  static sendNotification(sender, message, title, delay = 0, toastId = null) {
    const options = {
      autoClose: delay > 0 ? delay : false,
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      toastId,
    };

    const content = <NotificationContent title={title} message={message}/>;

    if (toastId && toast.isActive(TOAST_IDS_MAP[toastId])) {
      toast.update(TOAST_IDS_MAP[toastId], {
        ...options,
        render: content,
      });
      return;
    }

    TOAST_IDS_MAP[toastId] = sender(content, options);
  }

  /**
   * @param {?string|number} toastId
   */
  static dismissNotification(toastId) {
    if (!TOAST_IDS_MAP[toastId]) {
      return;
    }

    toast.dismiss(TOAST_IDS_MAP[toastId]);
  }

  static dismissAll() {
    toast.dismiss();
  }
}
