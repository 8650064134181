const STATUS_PENDING = 1;
const STATUS_LOADED = 2;

export default class AsyncLoader {
  /**
   * @private
   * @type {Object.<string, number>}
   */
  static statuses = {

  };

  /**
   * @private
   * @type {Object.<string, Promise<string>>}
   */
  static promises = {

  };

  /**
   * @param {string} src
   * @return {Element}
   */
  static createStyleElement(src) {
    const element = document.createElement('link');
    document.body.appendChild(element);
    element.href = src;
    element.type = 'text/css';
    element.rel = 'stylesheet';
    element.async = true;

    return element;
  }

  /**
   * @param {string} src
   * @return {Element}
   */
  static createScriptElement(src) {
    const element = document.createElement('script');
    document.body.appendChild(element);
    element.async = true;
    element.src = src;

    return element;
  }

  /**
   * @param {string} src
   * @param {string} contentType
   * @param {?function} callback
   * @return {Promise<string>}
   */
  static load(src, contentType, callback = null) {
    if (AsyncLoader.isLoaded(src)) {
      return Promise.resolve(src);
    }

    if (!AsyncLoader.statuses[src]) {
      const promise = new Promise((resolve, reject) => {
        let element = null;

        if (contentType === 'text/css') {
          element = AsyncLoader.createStyleElement(src);
        } else {
          element = AsyncLoader.createScriptElement(src);
        }

        element.onload = () => {
          if (callback) {
            callback();
          }

          resolve(src);
        };

        element.onerror = reject;
      });

      AsyncLoader.statuses[src] = STATUS_PENDING;
      AsyncLoader.promises[src] = promise;

      return promise;
    }

    return AsyncLoader.promises[src];
  }

  /**
   * @param {string} src
   * @return {boolean}
   */
  static isLoaded(src) {
    return AsyncLoader.statuses[src] === STATUS_LOADED;
  }
}
