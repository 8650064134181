import { observable } from 'mobx';

export default class CutshapePatent {
  /**
   * @type {string} country
   */
  @observable country = '';

  /**
   * @type {string} patentUrl
   */
  @observable patentUrl = '';

  /**
   * @type {string} title
   */
  @observable title = '';
}
