import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import {observable, toJS} from 'mobx';
import {CONTEXT_B2B, CONTEXT_LISTING} from '../../common/constants/context';
import {
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY, TYPE_JEWELRY,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY, TYPE_LGD_ROUGH, TYPE_ROUGH,
} from '../../product/constants/productTypes';
import {
  PHOTOREAL_PRODUCTS_EXCLUDED,
  PHOTOREAL_PRODUCTS_INCLUDED,
} from '../constants/photorealInclusionFilterValues';
import diff from '../../common/helpers/diff';
import {dictionaryRepository} from '@/common/repositories/DictionaryRepository';

// todo b2b, clarity, ... can receive one number
export default class Filters {
  /**
   * @type {?number}
   */
  @observable
  status = null;

  /**
   * @type {number[]}
   */
  @observable
  b2b = [];

  /**
   * @type {?string}
   */
  @observable
  b2bSid = null;

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  carat = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  price = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  clarity = [];

  /**
   * @type {number[]}
   */
  @observable
  color = [];

  /**
   * @type {{from: ?number, to: ?number}}
   * @description Date string in ISO 8601
   */
  @observable
  createdAt = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  cutQuality = [];

  /**
   * @type {number[]}
   */
  @observable
  cutShape = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorescenceStrength = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorescenceColor = [];

  /**
   * @type {?boolean}
   */
  @observable
  isFancyColor;

  /**
   * @type {boolean}
   */
  @observable
  isLabGrown;

  /**
   * @type {?number}
   */
  @observable
  photorealProductsInclusion = null;

  /**
   * @type {number[]}
   */
  @observable
  laboratory = [];

  /**
   * @type {string[]}
   */
  @observable
  treatment = [];

  /**
   * @type {string[]}
   */
  @observable
  milky = [];

  /**
   * @type {string[]}
   */
  @observable
  inclusions = [];

  /**
   * @type {number[]}
   */
  @observable
  access = [];

  /**
   * @type {number[]}
   */
  @observable
  polish = [];

  /**
   * @type {number[]}
   */
  @observable
  setupPreset = [];

  /**
   * @type {number[]}
   */
  @observable
  symmetry = [];

  /**
   * @type {number[]}
   */
  @observable
  culetSizeGrade = [];

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  girdleThicknessGrade = {
    from: null,
    to: null,
  };

  /**
   * @type {?boolean}
   */
  @observable
  withoutPrice = true;

  /**
   * @type {boolean}
   */
  @observable
  stereo = false;

  /**
   * @type {boolean}
   */
  @observable
  multifocus = false;

  /**
   * @type {number[]}
   */
  @observable
  fancyColorDescHue = [];

  /**
   * @type {number[]}
   */
  @observable
  fancyGrade = [];

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  spreadPc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  culetPc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  tablePc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  pavDepthPc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  crnHeightPc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  heightPc = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  spreadCt = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  length = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  width = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  rt = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  height = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  pavAn = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  pav1An = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  crnAn = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  fire = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  fireRG = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  scintillationRG = {
    from: null,
    to: null,
  }

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  brightness = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  brightnessRG = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  brightnessRG2 = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  brightnessDZ = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  opticalSymmetry = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  opticalPerformance = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  opticalPerformanceRG = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  shapeNormalizedPerformance = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  fluorGrade = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorColor = [];

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  avgBrightness = {
    from: null,
    to: null,
  };

  /**
   * @type {{from: ?number, to: ?number}}
   */
  @observable
  faceupBrightness = {
    from: null,
    to: null,
  };

  /**
   * @type {?boolean}
   */
  @observable
  withRough = null;

  /**
   * @type {?boolean}
   */
  @observable
  withDiamonds = null;

  /**
   * @type {?boolean}
   */
  @observable
  withPlotting = null;

  /**
   * @type {?boolean}
   */
  @observable
  withI3D = null;

  setCreatedAt(range) {
    this.createdAt = range;
  }

  /**
   * @param {Object} price
   */
  setPrice(price) {
    this.price = Filters.updateRange(price);
  }

  /**
   * @param {Object} carat
   */
  setCarat(carat) {
    this.carat = Filters.updateRange(carat);
  }

  /**
   * @param {Object} fire
   */
  setFire(fire) {
    this.fire = Filters.updateRange(fire);
  }

  /**
   * @param {Object} fireRG
   */
  setFireRG(fireRG) {
    this.fireRG = Filters.updateRange(fireRG);
  }

  /**
   * @param {Object} scintillationRG
   */
  setScintillationRg(scintillationRG) {
    this.scintillationRG = Filters.updateRange(scintillationRG);
  }

  /**
   * @param {Object} brightnessRG
   */
  setBrightnessRG(brightnessRG) {
    this.brightnessRG = Filters.updateRange(brightnessRG);
  }

  /**
   * @param {Object} brightnessRG2
   */
  setBrightnessRG2(brightnessRG2) {
    this.brightnessRG2 = Filters.updateRange(brightnessRG2);
  }

  /**
   * @param {Object} brightnessDZ
   */
  setBrightnessDZ(brightnessDZ) {
    this.brightnessDZ = Filters.updateRange(brightnessDZ);
  }

  /**
   * @param {Object} brightness
   */
  setBrightness(brightness) {
    this.brightness = Filters.updateRange(brightness);
  }

  /**
   * @param {Object} symmetry
   */
  setOpticalSymmetry(symmetry) {
    this.opticalSymmetry = Filters.updateRange(symmetry);
  }

  /**
   * @param {Object} opticalPerformance
   */
  setOpticalPerformance(opticalPerformance) {
    this.opticalPerformance = Filters.updateRange(opticalPerformance);
  }

  /**
   * @param {Object} opticalPerformanceRG
   */
  setOpticalPerformanceRG(opticalPerformanceRG) {
    this.opticalPerformanceRG = Filters.updateRange(opticalPerformanceRG);
  }

  /**
   * @param {Object} shapeNormalizedPerformance
   */
  setShapeNormalizedPerformance(shapeNormalizedPerformance) {
    this.shapeNormalizedPerformance = Filters.updateRange(shapeNormalizedPerformance);
  }

  /**
   * @param {Object} spreadCt
   */
  setSpreadCt(spreadCt) {
    this.spreadCt = Filters.updateRange(spreadCt);
  }

  /**
   * @param {Object} length
   */
  setLengthMm(length) {
    this.length = Filters.updateRange(length);
  }

  /**
   * @param {Object} width
   */
  setWidthMm(width) {
    this.width = Filters.updateRange(width);
  }

  /**
   * @param {Object} diameterRatio
   */
  setDiameterRatio(diameterRatio) {
    this.rt = Filters.updateRange(diameterRatio);
  }

  /**
   * @param {Object} height
   */
  setTotalHeightMm(height) {
    this.height = Filters.updateRange(height);
  }

  /**
   * @param {Object} pavAn
   */
  setPavilionAngleDeg(pavAn) {
    this.pavAn = Filters.updateRange(pavAn);
  }

  /**
   * @param {Object} pavAn
   */
  setPavilionFirstAngleDeg(pav1An) {
    this.pav1An = Filters.updateRange(pav1An);
  }

  /**
   * @param {Object} crnAn
   */
  setCrownAngleDeg(crnAn) {
    this.crnAn = Filters.updateRange(crnAn);
  }

  /**
   * @param {Object} spreadPc
   */
  setSpreadPc(spreadPc) {
    this.spreadPc = Filters.updateRange(spreadPc);
  }

  /**
   * @param {Object} culetPc
   */
  setCuletPc(culetPc) {
    this.culetPc = Filters.updateRange(culetPc);
  }

  /**
   * @param {Object} tablePc
   */
  setTablePc(tablePc) {
    this.tablePc = Filters.updateRange(tablePc);
  }

  /**
   * @param {Object} pavDepthPc
   */
  setPavilionHeightPcAvg(pavDepthPc) {
    this.pavDepthPc = Filters.updateRange(pavDepthPc);
  }

  /**
   * @param {Object} crnHeightPc
   */
  setCrownHeightPcAvg(crnHeightPc) {
    this.crnHeightPc = Filters.updateRange(crnHeightPc);
  }

  /**
   * @param {Object} heightPc
   */
  setTotalHeightPc(heightPc) {
    this.heightPc = Filters.updateRange(heightPc);
  }

  /**
   * @param {Object} girdleThicknessGrade
   */
  setGirdleThicknessGrade(girdleThicknessGrade) {
    this.girdleThicknessGrade = Filters.updateRange(girdleThicknessGrade);
  }

  /**
   * @param {Object} value
   */
  setAvgBrightness(value) {
    this.avgBrightness = Filters.updateRange(value);
  }

  /**
   * @param {Object} value
   */
  setFaceupBrightness(value) {
    this.faceupBrightness = Filters.updateRange(value);
  }

  /**
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  static make(productType, context = CONTEXT_LISTING) {
    const filters = new Filters();

    if (productType === TYPE_DIAMOND_COLORLESS) {
      filters.isFancyColor = false;
      filters.isLabGrown = false;
    }

    if (productType === TYPE_DIAMOND_FANCY) {
      filters.isFancyColor = true;
      filters.isLabGrown = false;
    }

    if (productType === TYPE_LGD_COLORLESS) {
      filters.isFancyColor = false;
      filters.isLabGrown = true;
    }

    if (productType === TYPE_LGD_FANCY) {
      filters.isFancyColor = true;
      filters.isLabGrown = true;
    }

    if (productType === TYPE_LGD_ROUGH) {
      filters.isLabGrown = true;
    }

    if (productType === TYPE_ROUGH) {
      filters.isLabGrown = false;
    }

    if (productType !== TYPE_JEWELRY && productType !== TYPE_ROUGH && productType !== TYPE_LGD_ROUGH) {
      filters.photorealProductsInclusion = PHOTOREAL_PRODUCTS_EXCLUDED;
    }

    if (context === CONTEXT_B2B) {
      filters.photorealProductsInclusion = PHOTOREAL_PRODUCTS_INCLUDED;
    }

    return filters;
  }

  /**
   * @param {Object} jsonData
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  static fromJSON(jsonData, productType, context = CONTEXT_LISTING) {
    const filters = Filters.make(productType, context);
    filters.apply(jsonData);

    return filters;
  }

  static dictionaryValues(dictValues) {
    const res = [];
    dictValues.forEach((v) => {
      res.push(+v);
    });
    return res;
  }

  static dictionaryValuesById(dict, ids) {
    const result = []

    ids.forEach((id) => {
      let item = dictionaryRepository.findItemInDictionaryById(dict, id);

      if (item) {
        result.push(item)
      }
    })

    return result;
  }

  static updateRange(updateObj) {
    const newRange = {
      from: null,
      to: null,
    };

    const from = Number(updateObj.from);
    const to = Number(updateObj.to);

    if (updateObj.from !== null && isNumber(from) && isFinite(from)) {
      newRange.from = from;
    }

    if (updateObj.to !== null && isNumber(to) && isFinite(to)) {
      newRange.to = to;
    }

    return newRange;
  }

  isEqual(object) {
    return isEmpty(this.diff(object));
  }

  diff(otherObject) {
    return diff(toJS(otherObject), toJS(this));
  }

  /**
   * Clone filters for specific product type
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  clone(productType, context = CONTEXT_LISTING) {
    const clone = Filters.make(productType, context);
    Object.assign(clone, this);

    return clone;
  }

  /**
   * @param {Object} jsonData
   */
  apply(jsonData) {
    if (jsonData.b2b !== undefined) {
      this.b2b = jsonData.b2b.map(v => Number(v));
    }

    if (jsonData.b2bSid !== undefined) {
      this.b2bSid = jsonData.b2bSid;
    }

    if (jsonData.carat !== undefined) {
      this.setCarat(jsonData.carat);
    }

    if (jsonData.price !== undefined) {
      this.setPrice(jsonData.price);
    }

    if (jsonData.clarity !== undefined) {
      this.clarity = Filters.dictionaryValues(jsonData.clarity);
    }

    if (jsonData.color !== undefined) {
      this.color = Filters.dictionaryValues(jsonData.color);
    }

    if (jsonData.createdAt && jsonData.createdAt.from && isString(jsonData.createdAt.from)) {
      jsonData.createdAt.from = jsonData.createdAt.from;
    }

    if (jsonData.createdAt && jsonData.createdAt.to && isString(jsonData.createdAt.to)) {
      jsonData.createdAt.to = jsonData.createdAt.to;
    }

    if (jsonData.createdAt !== undefined) {
      this.setCreatedAt(jsonData.createdAt);
    }

    if (jsonData.cutQuality !== undefined) {
      this.cutQuality = Filters.dictionaryValues(jsonData.cutQuality);
    }

    if (jsonData.cutShape !== undefined) {
      this.cutShape = Filters.dictionaryValues(jsonData.cutShape);
    }

    if (jsonData.fluorescenceStrength !== undefined) {
      this.fluorescenceStrength = Filters.dictionaryValues(jsonData.fluorescenceStrength);
    }

    if (jsonData.fluorescenceColor !== undefined) {
      this.fluorescenceColor = Filters.dictionaryValues(jsonData.fluorescenceColor);
    }

    if (jsonData.isFancyColor !== undefined) {
      this.isFancyColor = jsonData.isFancyColor;
    }

    if (jsonData.isLabGrown !== undefined) {
      this.isLabGrown = jsonData.isLabGrown;
    }

    if (jsonData.laboratory !== undefined) {
      this.laboratory = Filters.dictionaryValues(jsonData.laboratory);
    }

    if (jsonData.access !== undefined) {
      this.access = Filters.dictionaryValues(jsonData.access);
    }

    if (jsonData.polish !== undefined) {
      this.polish = Filters.dictionaryValues(jsonData.polish);
    }

    if (jsonData.setupPreset !== undefined) {
      this.setupPreset = Filters.dictionaryValues(jsonData.setupPreset);
    }

    if (jsonData.symmetry !== undefined) {
      this.symmetry = Filters.dictionaryValues(jsonData.symmetry);
    }

    if (jsonData.culetSizeGrade !== undefined) {
      this.culetSizeGrade = Filters.dictionaryValues(jsonData.culetSizeGrade);
    }

    if (jsonData.girdleThicknessGrade !== undefined) {
      this.setGirdleThicknessGrade(jsonData.girdleThicknessGrade);
    }

    if (jsonData.withoutPrice !== undefined) {
      this.withoutPrice = jsonData.withoutPrice === true || jsonData.withoutPrice === 'true' || jsonData.withoutPrice === '1';
    }

    if (jsonData.stereo !== undefined) {
      this.stereo = jsonData.stereo === true || jsonData.stereo === 'true' || jsonData.stereo === '1';
    }

    if (jsonData.multifocus !== undefined) {
      this.multifocus = jsonData.multifocus === true || jsonData.multifocus === 'true' || jsonData.multifocus === '1';
    }

    if (jsonData.fancyColorDescHue !== undefined) {
      this.fancyColorDescHue = Filters.dictionaryValues(jsonData.fancyColorDescHue);
    }

    if (jsonData.fancyGrade !== undefined) {
      this.fancyGrade = Filters.dictionaryValues(jsonData.fancyGrade);
    }

    if (jsonData.fire !== undefined) {
      this.setFire(jsonData.fire);
    }

    if (jsonData['fire.rg'] !== undefined) {
      this.setFireRG(jsonData['fire.rg']);
    }

    if (jsonData['scintillation.rg'] !== undefined) {
      this.setScintillationRg(jsonData['scintillation.rg']);
    }

    if (jsonData.brightness !== undefined) {
      this.setBrightness(jsonData.brightness);
    }

    if (jsonData['brightness.rg'] !== undefined) {
      this.setBrightnessRG(jsonData['brightness.rg']);
    }

    if (jsonData.opticalSymmetry !== undefined) {
      this.setOpticalSymmetry(jsonData.opticalSymmetry);
    }

    if (jsonData.opticalPerformance !== undefined) {
      this.setOpticalPerformance(jsonData.opticalPerformance);
    }

    if (jsonData['opticalPerformance.rg'] !== undefined) {
      this.setOpticalPerformanceRG(jsonData['opticalPerformance.rg']);
    }

    if (jsonData['integral.smc'] !== undefined) {
      this.setShapeNormalizedPerformance(jsonData['integral.smc']);
    }

    if (jsonData.spreadCt !== undefined) {
      this.setSpreadCt(jsonData.spreadCt);
    }

    if (jsonData.length !== undefined) {
      this.setLengthMm(jsonData.length);
    }

    if (jsonData.width !== undefined) {
      this.setWidthMm(jsonData.width);
    }

    if (jsonData.rt !== undefined) {
      this.setDiameterRatio(jsonData.rt);
    }

    if (jsonData.height !== undefined) {
      this.setTotalHeightMm(jsonData.height);
    }

    if (jsonData.pavAn !== undefined) {
      this.setPavilionAngleDeg(jsonData.pavAn);
    }

    if (jsonData.pav1An !== undefined) {
      this.setPavilionFirstAngleDeg(jsonData.pav1An);
    }

    if (jsonData.crnAn !== undefined) {
      this.setCrownAngleDeg(jsonData.crnAn);
    }

    if (jsonData.spreadPc !== undefined) {
      this.setSpreadPc(jsonData.spreadPc);
    }

    if (jsonData.culetPc !== undefined) {
      this.setCuletPc(jsonData.culetPc);
    }

    if (jsonData.tablePc !== undefined) {
      this.setTablePc(jsonData.tablePc);
    }

    if (jsonData.pavDepthPc !== undefined) {
      this.setPavilionHeightPcAvg(jsonData.pavDepthPc);
    }

    if (jsonData.crnHeightPc !== undefined) {
      this.setCrownHeightPcAvg(jsonData.crnHeightPc);
    }

    if (jsonData.heightPc !== undefined) {
      this.setTotalHeightPc(jsonData.heightPc);
    }

    if (jsonData.avgBrightness !== undefined) {
      this.setAvgBrightness(jsonData.avgBrightness);
    }

    if (jsonData.faceupBrightness !== undefined) {
      this.setFaceupBrightness(jsonData.faceupBrightness);
    }

    if (jsonData['fluor.grade'] !== undefined) {
      this.fluorGrade = Filters.dictionaryValues(jsonData['fluor.grade']);
    }

    if (jsonData['fluor.color'] !== undefined) {
      this.fluorColor = Filters.dictionaryValues(jsonData['fluor.color']);
    }

    if (jsonData.withRough !== undefined) {
      this.withRough = jsonData.withRough;
    }

    if (jsonData.withDiamonds !== undefined) {
      this.withDiamonds = jsonData.withDiamonds;
    }

    if (jsonData.withPlotting !== undefined) {
      this.withPlotting = jsonData.withPlotting;
    }

    if (jsonData.withI3D !== undefined) {
      this.withI3D = jsonData.withI3D;
    }

    if (jsonData.status !== undefined) {
      this.status = Number(jsonData.status);
    }

    if (jsonData.photorealProductsInclusion !== undefined) {
      this.photorealProductsInclusion = Number(jsonData.photorealProductsInclusion);
    }

    if (jsonData.treatment !== undefined) {
      this.treatment = jsonData.treatment;
    }

    if (jsonData.milky !== undefined) {
      this.milky = jsonData.milky;
    }

    ['openInc', 'whiteInc', 'blackInc'].forEach((key) => {
      if (jsonData[key] && Array.isArray(jsonData[key])) {
        this.inclusions = this.inclusions.concat(
          Filters.dictionaryValuesById(key, jsonData[key]).map(({ title }) => title)
        );
      }
    })
  }
}
