export default class BrightnessMetric {
  /**
   * @type {string[]}
   */
  ag;

  /**
   * @type {string}
   */
  avg;

  /**
   * @type {number}
   */
  d;

  /**
   * @type {string}
   */
  faceup;

  /**
   * @type {string}
   */
  hr;

  /**
   * grade relative to Tolkowsky stone of same size
   * @type {number}
   */
  rg;

  /**
   * rg for second generation of brilliance metric
   * @type {number}
   */
  rg2;

  /**
   * dead zone value
   */
  dz;

  /**
   * @type {string}
   */
  val;


  /**
   * value for second generation of brilliance metric
   * @type {string}
   */
  val2;

  /**
   * @type {string}
   */
  ver;

  /**
   * Contains calculated value for radar,
   * value in range in between 0 and 1
   *
   * @type {number}
   */
  gmc;

  /**
   * gmc for second generation of brilliance metric
   * @type {number}
   */
  gmc2;

  /**
   * @param {string[]} ag
   * @param {number} avg
   * @param {number} d
   * @param {number} faceup
   * @param {string} hr
   * @param {number} rg
   * @param {number} rg2
   * @param {number} dz
   * @param {number} val
   * @param {string} ver
   * @param {number} gmc
   * @param {number} gmc2
   */
  constructor({
    ag, avg, d, faceup, hr, rg, rg2, val, val2, ver, gmc, gmc2, dz,
  }) {
    this.ag = ag;
    this.avg = avg ? avg.toFixed(2) : null;
    this.d = d;
    this.faceup = faceup ? faceup.toFixed(2) : null;
    this.hr = hr;
    this.rg = rg;
    this.rg2 = rg2;
    this.val = val ? val.toFixed(2) : null;
    this.val2 = val2 ? val2.toFixed(2) : null;
    this.ver = ver;
    this.gmc = gmc;
    this.gmc2 = gmc2;
    this.dz = dz;
  }
}
